exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-prompt-engineering-js": () => import("./../../../src/pages/ai-prompt-engineering.js" /* webpackChunkName: "component---src-pages-ai-prompt-engineering-js" */),
  "component---src-pages-ai-prompt-engineering-use-case-1-js": () => import("./../../../src/pages/ai-prompt-engineering-use-case-1.js" /* webpackChunkName: "component---src-pages-ai-prompt-engineering-use-case-1-js" */),
  "component---src-pages-blockchain-distributed-ledger-js": () => import("./../../../src/pages/blockchain-distributed-ledger.js" /* webpackChunkName: "component---src-pages-blockchain-distributed-ledger-js" */),
  "component---src-pages-blockchain-distributed-ledger-use-case-1-js": () => import("./../../../src/pages/blockchain-distributed-ledger-use-case-1.js" /* webpackChunkName: "component---src-pages-blockchain-distributed-ledger-use-case-1-js" */),
  "component---src-pages-cloud-computing-js": () => import("./../../../src/pages/cloud-computing.js" /* webpackChunkName: "component---src-pages-cloud-computing-js" */),
  "component---src-pages-cloud-computing-use-case-1-js": () => import("./../../../src/pages/cloud-computing-use-case-1.js" /* webpackChunkName: "component---src-pages-cloud-computing-use-case-1-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cyber-security-js": () => import("./../../../src/pages/cyber-security.js" /* webpackChunkName: "component---src-pages-cyber-security-js" */),
  "component---src-pages-cyber-security-use-case-1-js": () => import("./../../../src/pages/cyber-security-use-case-1.js" /* webpackChunkName: "component---src-pages-cyber-security-use-case-1-js" */),
  "component---src-pages-digital-transformation-it-consulting-js": () => import("./../../../src/pages/digital-transformation-it-consulting.js" /* webpackChunkName: "component---src-pages-digital-transformation-it-consulting-js" */),
  "component---src-pages-digital-transformation-it-consulting-use-case-1-js": () => import("./../../../src/pages/digital-transformation-it-consulting-use-case-1.js" /* webpackChunkName: "component---src-pages-digital-transformation-it-consulting-use-case-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-edge-computing-js": () => import("./../../../src/pages/iot-edge-computing.js" /* webpackChunkName: "component---src-pages-iot-edge-computing-js" */),
  "component---src-pages-iot-edge-computing-use-case-1-js": () => import("./../../../src/pages/iot-edge-computing-use-case-1.js" /* webpackChunkName: "component---src-pages-iot-edge-computing-use-case-1-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-robotics-process-automation-js": () => import("./../../../src/pages/robotics-process-automation.js" /* webpackChunkName: "component---src-pages-robotics-process-automation-js" */),
  "component---src-pages-robotics-process-automation-use-case-1-js": () => import("./../../../src/pages/robotics-process-automation_use_case_1.js" /* webpackChunkName: "component---src-pages-robotics-process-automation-use-case-1-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

